.header {
}

a {
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  /* background-color: red; */
  /* border: solid; */
  flex: 1;
  list-style-type: none;
  padding: 0;
}

.headerLeft li {
  position: relative;
  display: flex;
}

.headerLeft li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 2px black;
  border-color: #7fb842;
  transform: scaleX(0);
  transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
  -webkit-transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
  -moz-transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
  -ms-transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
  -o-transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
}

.headerLeft li:hover a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 2px black;
  border-color: #7fb842;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}

.headerLeft li a {
  display: block;
  position: relative;
  padding: 8px 15px;
  color: #000000;
  position: relative;
  text-decoration: none;
  font-family: "Proxima Nova", Sans-serif;
  font-weight: 600;
}

.show-hide {
  display: none;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .headerLeft {
    flex-direction: column;
    display: none;
  }
  .headerLeft.show-dropdown {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
  }

  .show-hide {
    display: flex;
  }

  .show-hide.open {
    height: auto;
  }
}
