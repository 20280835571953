.hero {
  background-color: #1d3356;
  background-position: center 36%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 532px;
  display: flex;
}

.hero .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.hero-content {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.hero-content h1 {
  color: #ffffff;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 37px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1em;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 50px;
}

.hero-content button {
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1em;
  letter-spacing: 0px;
  color: #fff;
  background-color: #1d3356;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1d3356;
  border-radius: 4px 4px 4px 4px;
  padding: 16px 55px 16px 55px;
}

.hero-content button:hover {
  background-color: #1d3356;
}

/* SECTION 2  */
.section-2 {
  min-height: 386px;
  display: flex;
  padding: 20px 0;
}

.section-2 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-2 .section2-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}

.section-2 .section2-content h2 {
  color: #7fb842;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 1px;
}

.section-2 .section2-content h3 {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 23px;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
}

.section-2 .section2-content p {
  margin-top: 10px;
  color: #324a6d;
}

.section2-image {
  margin-right: 10px;
}

.section2-image img {
  width: 100%;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

/* SECTION 3  */
.section-3 {
  min-height: 323px;
  display: flex;
  padding: 20px 0;
  background-color: transparent;
  background-image: linear-gradient(105deg, #3e5069 0%, #1e3457 100%);
}

.section-3 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-3 h2 {
  color: #7fb842;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 1px;
  text-align: center;
}

.section3-item-container {
  margin: auto;
  justify-content: center;
}

.section-3 .section3-item span {
  color: #ffffff;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 19px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  text-align: center;
  justify-content: center;
}
.section-3 .section3-item h5 {
  color: #ffffff;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 37px;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 0.1em;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 24px;
}

.section-3 a {
  text-decoration: none;
  cursor: pointer;
}

.section-3 a:hover {
  text-decoration: underline;
  text-decoration-color: #fff;
  -moz-text-decoration-color: #fff;
}

/* SECTION 4 */
.section-4 {
  min-height: 323px;
  display: flex;
  padding: 20px 0;
}

.section-4 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-4 .section4-content {
  text-align: center;
}

.section-4 .section4-content h2 {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 41px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
}

.section-4 .section4-content a {
}
.section-4 .section4-content button {
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1em;
  letter-spacing: 0px;
  color: #fff;
  background-color: #1d3356;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #1d3356;
  border-radius: 4px 4px 4px 4px;
  padding: 16px 55px 16px 55px;
}

.section-4 .section4-content button:hover {
  background-color: #1d3356;
}

/* Section 5 */
.section-5 {
  min-height: 323px;
  display: flex;
  padding: 20px 0;
  background-color: #f3f5f8;
}

.section-5 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-5 .section5-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section-5 .section5-content h2 {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 41px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  margin: 0;
}
.section-5 .section5-content p {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.section-5 .section5-item-container {
}

.section-5 .section5-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.section-5 .section5-item p {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.section-5 .section5-item h5 {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 23px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  margin: 0;
}

.section-5 .section5-item a {
  font-family: "Proxima Nova", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1em;
  letter-spacing: 0px;
  color: #d4147b;
}

.section-5 .section5-item strong {
  color: #324a6d;
}

/* SECTION 6 */
.section-6 {
  min-height: 323px;
  display: flex;
  padding: 20px 0;
}

.section-6 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-6 .section6-content {
  text-align: center;
}

.section-6 .section6-content h2 {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 41px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
}
.section-6 .section6-content p {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.section-6 .section6-item {
  margin: 10px 0;
}
.section-6 .section6-item a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.section-6 .section6-item a span {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 19px;
  font-weight: 500;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0px;
}
.section-6 .section6-item a strong {
  color: #324a6d;
  font-family: "Proxima Nova", Sans-serif;
  font-size: 23px;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0px;
}

/* SECTION 7 */
.section-7 {
  min-height: 323px;
  display: flex;
  padding: 20px 0;
  background-color: #120f1f;
}

.section-7 .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section-7 .section7-item-container {
  text-align: center;
}

.section-7 .section7-item {
  color: #fff;
}
